.app-header-subpage {
    position: relative;
    border-bottom: 1px grey solid;
}

.app-header-subpage h2 {
    text-align: center;
}

.subpage-back-button {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 2rem;
    cursor: pointer;
}
