body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-color: #efefef;
}

.mobile_only {
    margin: 0 auto;
    border-radius: 10px;
    padding: 10px 20px;
    position: relative;
}
@media (min-width: 500px) {
    .mobile_only {
        margin-top: 20px;
        max-width: 400px;
        height: 800px;
        max-height: 100vh;
        background-color: #efefef;
    }
    body {
        background-color: white;
    }
}
h1 {
    text-align: center;
}
header {
    border-bottom: 1px grey solid;
}

.message {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    text-align: center;
    
}
.lesson {
    padding: 10px 20px;
    border: 1px black solid;
    border-radius: 10px;
    margin: 5px 0;
    display: inline-block;
}
.breadcrumbs_navigation {
    font-size: 0.7rem;
    margin-bottom: 2rem;
}

.breadcrumbs_navigation .breadcrumb-active {
    color: grey;
}

.app-header h2 {
    text-align: center;
}
.button {
    padding: 10px 8px;
    border-radius: 10px;
    text-decoration: none; 
    color: white;
    background-color: black;
    border: 2px black solid;
    display: inline-block;
}

.button:hover {
    background-color: white;
    color: black;
}

.button.light {
    color: black;
    background-color: white;
    border: 2px black solid;
}
.button.light:hover {
    background-color: black;
    color: white;
}

.challenge_entry {
    background-color: #fff;
    padding: 15px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.challenge_entry .title {
    font-weight: bold;
    margin: 0 0 10px 0;
}

.challenge_entry p {
    margin: 0;
}
.app-header-subpage {
    position: relative;
    border-bottom: 1px grey solid;
}

.app-header-subpage h2 {
    text-align: center;
}

.subpage-back-button {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 2rem;
    cursor: pointer;
}



