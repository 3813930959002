body {
    background-color: #efefef;
}

.mobile_only {
    margin: 0 auto;
    border-radius: 10px;
    padding: 10px 20px;
    position: relative;
}
@media (min-width: 500px) {
    .mobile_only {
        margin-top: 20px;
        max-width: 400px;
        height: 800px;
        max-height: 100vh;
        background-color: #efefef;
    }
    body {
        background-color: white;
    }
}
h1 {
    text-align: center;
}
header {
    border-bottom: 1px grey solid;
}

.message {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    text-align: center;
    
}
.lesson {
    padding: 10px 20px;
    border: 1px black solid;
    border-radius: 10px;
    margin: 5px 0;
    display: inline-block;
}