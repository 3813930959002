.challenge_entry {
    background-color: #fff;
    padding: 15px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.challenge_entry .title {
    font-weight: bold;
    margin: 0 0 10px 0;
}

.challenge_entry p {
    margin: 0;
}