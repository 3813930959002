.button {
    padding: 10px 8px;
    border-radius: 10px;
    text-decoration: none; 
    color: white;
    background-color: black;
    border: 2px black solid;
    display: inline-block;
}

.button:hover {
    background-color: white;
    color: black;
}

.button.light {
    color: black;
    background-color: white;
    border: 2px black solid;
}
.button.light:hover {
    background-color: black;
    color: white;
}
